<script lang="ts" setup>
import { useApiRequest } from '~/composables/useApiRequest'
import { apiStore } from '~/stores/api'
import { entityTypesStore } from '~/stores/entity_types'
import { unwrapApiErrors } from '~/types/api'
import { ToastList } from '~/types/toast'
import { CreateBom, CreateEntity, Entity } from '~/types/view-elements'

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  defaultType: {
    type: String,
    required: false,
    default: '',
  },
  isBomPage: {
    type: Boolean,
    required: false,
    default: false,
  },
  isDiversity: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const emit = defineEmits<{
  close: []
  create: [entity: object]
}>()

const state = reactive({
  isLoading: false,
  file: null as File | null,
  toast: inject('toast') as ToastList,
  cx_has_variability: false,
  dragOverActive: false,
  isExisting: false,
  uploadFilePath: '',
})

const data = reactive({
  description: '',
  name: '',
  image: '',
  reference: '',
  schema_type: '',
  status: 'draft',
  attributes: [],
  uuid: '',
  id: '',
})

// Computed property to sort entity types
const sortedEntityTypes = computed(() => {
  return entityTypesStore()
    .getEntityTypesAsOptionsWithNameAsValue.filter(
      e => !['Project', 'Supplier'].includes(e.name) && e.deleted_at === null,
    )
    .sort((a, b) => (a.display_name || a.name).localeCompare(b.display_name || b.name))
})

const isSaveDisabled = computed(() => {
  return !data.name || (!data.schema_type && !props.defaultType) || state.isLoading
})

function closeModal() {
  emit('close')
}
function prefillWithEntityData(entity: Entity) {
  data.name = entity.name
  data.description = entity.description
  data.image = entity.image_url
  data.schema_type = entity.schema_type
  data.status = entity.status
  data.uuid = entity.uuid
  data.id = entity.id
  data.attributes = entity.attributes
  state.isExisting = true
}

function resetData() {
  data.name = ''
  data.description = ''
  data.schema_type = ''
  data.reference = ''
  state.file = null
  state.cx_has_variability = false
  data.attributes = []
}

function setDragHoverActive() {
  state.dragOverActive = true
}
function setDragHoverInactive() {
  state.dragOverActive = false
}

function handleFileSubmit(event: unknown) {
  let file: File | null = null

  if (event.type === 'drop') {
    file = event.dataTransfer.files[0]
    state.dragOverActive = false
  } else {
    file = event.target.files[0]
  }

  if (file) state.file = file
}

function handleFileReset() {
  const input = document.getElementById('add-entity-image') as HTMLInputElement
  input.value = ''
  state.file = null
}

// Utility function to format the schema_type
function formatEntityType(type: string): string {
  if (type.includes('>')) {
    const formattedType = type.split('>').pop()?.trim()
    return formattedType || type
  }

  return type
}

const uploadRequest = useApiRequest(async api => {
  if (state.file) {
    const uploadedFile = await api.uploadPublicAsset(state.file)
    state.uploadFilePath = uploadedFile.path
  }
})

async function createEntity() {
  state.isLoading = true
  const api = apiStore().getApiClient
  const entityType = sortedEntityTypes.value.find(item => item.name === data.schema_type)
  try {
    const payload: CreateEntity = {
      name: data.name,
      description: data.description,
      status: data.status,
      reference: data.reference,
      entity_type_id: entityType?.id,
      schema_type: formatEntityType(props.defaultType || data.schema_type),
      attributes: data.attributes,
    }

    if (state.file) {
      if (
        !state.file.type.startsWith('image/') ||
        state.file.type == 'image/bmp' ||
        state.file.type == 'image/svg+xml'
      ) {
        state.toast?.error(t('global.error'), t('global.invalid_image_error'))
        return
      }

      const result = await uploadRequest.runRequest()
      if (!result) {
        return
      }
      payload.image_url = state.uploadFilePath
      payload.image = state.uploadFilePath
    }

    if (state.cx_has_variability) {
      payload.attributes.push({
        slug: 'cx_has_variability',
        value: state.cx_has_variability,
      })
    }

    let result = {}
    if (props.isDiversity) {
      if (state.isExisting) {
        result = await api.getDiversity(data.id)

        if (result.data.name != data.name || result.data.description != data.description) {
          result = await api.createDiversity(payload)
        }
      } else {
        result = await api.createDiversity(payload)
      }
    } else {
      result = await api.createEntity(payload)
    }

    state.toast.success(t('global.success'), t('global.entity_created'))
    emit('create', result.data)
  } catch (error: unknown) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    setTimeout(() => {
      state.isLoading = false
      resetData()
    }, 250)
    closeModal()
  }
}

async function createBom() {
  state.isLoading = true
  const api = apiStore().getApiClient

  try {
    const payload: CreateBom = {
      name: data.name,
      description: data.description,
      status: data.status,
      reference: data.reference,
      variability_configurations: [],
    }

    if (state.file) {
      if (!state.file.type.startsWith('image/')) {
        state.toast.error(t('global.error'), t('global.invalid_image_error'))
        return
      }

      const result = await uploadRequest.runRequest()
      if (!result) {
        return
      }
      payload.image_url = state.uploadFilePath
      payload.image = state.uploadFilePath
    }

    if (state.cx_has_variability) {
      payload.attributes.push({
        slug: 'cx_has_variability',
        value: state.cx_has_variability,
      })
    }

    const result = await api.createBom(payload)

    state.toast.success(t('global.success'), t('global.bom_created'))
    emit('create', result.data)
  } catch (error: unknown) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    setTimeout(() => {
      state.isLoading = false
      resetData()
    }, 250)
    closeModal()
  }
}
</script>

<template>
  <OModal :open="props.open" @close="closeModal">
    <template #content>
      <div
        class="flex flex-col -m-6"
        @dragenter.prevent="setDragHoverActive"
        @dragover.prevent="setDragHoverActive"
        @dragleave.prevent="setDragHoverInactive"
        @drop.prevent="handleFileSubmit"
      >
        <div class="flex justify-between gap-2 py-4 px-6">
          <div class="flex items-center gap-2">
            <h2 class="text-xl font-semibold text-gray-900 line-clamp-1">
              {{
                props.isDiversity
                  ? $t('global.create_or_attach_diversity')
                  : props.isBomPage
                    ? $t('global.create_bom')
                    : $t('global.create_entity')
              }}
            </h2>
            <span
              v-if="props.defaultType"
              class="flex items-center h-fit py-0.25 px-1.25 text-xs rounded-full bg-gray-200 text-gray-500"
              :class="{ 'px-2': props.defaultType === 'ProductConfiguration' }"
            >
              {{ props.defaultType === 'ProductConfiguration' ? t('global.diversity') : props.defaultType }}
            </span>
          </div>
          <div class="flex items-center gap-3">
            <button class="text-gray-500" @click="emit('close')">
              <CIcon name="close" class="w-6 h-6" />
            </button>
          </div>
        </div>

        <span class="h-[1px] w-full bg-gray-200" />

        <div class="flex flex-col w-full px-6 pt-4 gap-4">
          <!-- search -->
          <template v-if="props.isDiversity">
            <h3 class="text-lg font-semibold text-gray-900">{{ $t('global.diversity_attach') }}:</h3>
            <InstanceCatalogEntityCard
              :is-diversity="props.isDiversity"
              :is-editing="true"
              @select-entity="prefillWithEntityData"
            />

            <div class="relative h-[1px] w-full bg-gray-200 mt-4 mb-2">
              <div class="absolute left-1/2 flex w-fit px-2 bg-white text-gray-400 -mt-3 capitalize -translate-x-1/2">
                {{ $t('global.or') }}
              </div>
            </div>
          </template>

          <!-- search -->
          <div class="flex flex-col gap-1" v-if="!props.isDiversity">
            <p class="text-sm text-gray-500">{{ $t('global.reference') }}</p>
            <FormKit
              type="text"
              v-model="data.reference"
              name="reference"
              :placeholder="$t('global.reference')"
              input-class="!text-sm"
            />
          </div>

          <h3 v-if="props.isDiversity" class="text-lg font-semibold text-gray-900">
            {{ $t('global.diversity_creation') }}:
          </h3>
          <div class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.name') }}</p>
            <FormKit
              type="text"
              v-model="data.name"
              name="name"
              :placeholder="$t('global.name')"
              input-class="!text-sm"
            />
          </div>

          <div class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.description') }}</p>
            <FormKit
              type="textarea"
              v-model="data.description"
              name="description"
              :placeholder="$t('global.description')"
              input-class="!text-sm"
            />
          </div>

          <div v-if="!props.defaultType" class="flex flex-col gap-1">
            <p class="text-sm text-gray-500">{{ $t('global.type') }}</p>
            <FormKit
              type="autocomplete"
              v-model="data.schema_type"
              name="schema_type"
              :options="sortedEntityTypes"
              :placeholder="$t('global.type')"
              input-class="!text-sm"
              suffix-icon="carbon-chevron-down"
            />
          </div>

          <div class="mb-4">
            <div class="formkit-wrapper" v-if="!props.isDiversity">
              <label class="text-sm text-gray-500 text-left">{{ $t('projects.select_image') }}</label>
              <div class="relative flex items-center justify-center w-full">
                <label
                  for="add-entity-image"
                  class="group flex flex-col items-center justify-center w-full h-32 border border-dashed bg-white hover:bg-gray-50 cursor-pointer rounded transition"
                  :class="state.dragOverActive ? 'border-blue-500' : 'border-gray-300'"
                >
                  <div>
                    <div class="flex flex-col items-center justify-center w-full h-full pt-1 pb-2">
                      <div>
                        <CIcon name="cloud-upload" class="w-8 h-8 mb-2 text-gray-500" />
                      </div>
                      <p class="mb-2 text-sm text-gray-500">
                        <span class="font-semibold">{{ `${$t('file_upload.click_cta')}` }}</span>
                        <!-- <span>{{ `, ${$t('file_upload.click_or_drag_cta')}.` }}</span> -->
                      </p>
                      <p v-if="!state.file" class="text-xs text-gray-500">
                        {{ $t('global.upload_image') }}
                      </p>
                      <div v-else class="flex gap-1 text-sm text-gray-500">
                        {{ state.file.name }}
                        <button class="flex justify-center items-center mt-0.5" @click.stop.prevent="handleFileReset">
                          <CIcon name="close" class="w-4 h-4 text-gray-500" />
                        </button>
                      </div>
                    </div>

                    <input
                      id="add-entity-image"
                      type="file"
                      class="hidden"
                      accept="image/*"
                      @input="handleFileSubmit"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div class="flex justify-end w-full gap-4">
        <button class="btn-secondary" @click="closeModal">
          {{ $t('global.cancel') }}
        </button>
        <button
          class="btn-primary"
          v-if="!props.isDiversity"
          @click="props.isBomPage ? createBom() : createEntity()"
          :disabled="isSaveDisabled"
        >
          {{ state.isLoading ? $t('global.creating') : $t('global.create') }}
        </button>
        <button
          class="btn-primary"
          v-if="props.isDiversity"
          @click="props.isBomPage ? createBom() : createEntity()"
          :disabled="isSaveDisabled"
        >
          {{
            state.isLoading
              ? state.isExisting
                ? $t('global.attaching')
                : $t('global.creating')
              : state.isExisting
                ? $t('global.attach')
                : $t('global.create_or_attach')
          }}
        </button>
      </div>
    </template>
  </OModal>
</template>
